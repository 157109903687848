export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'username',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Store',
    },
    cell: {
      type: 'slot',
      name: 'storename',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Web site',
    },
    cell: {
      type: 'slot',
      name: 'webSiteUrl',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Quantity',
    },
    cell: {
      type: 'slot',
      name: 'quantity',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
];
